import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ReedmakerConfig from "../../content/reedmaker.yaml"

const CheckoutSuccessPage = () => {
    const { content } = ReedmakerConfig

    return (
        <Layout>
            <div className="container mx-auto p-6 py-16">
                <div className="flex flex-wrap">
                    <SEO title="checkout" />

                    <section className={`w-full p-3`}>                  
                        <h1 className="font-bold text-3xl md:text-6xl mb-3 text-primary">
                            {content.cart.checkoutSuccess.title}
                        </h1>
                        <div id="main-content-block">
                            {content.cart.checkoutSuccess.description}
                        </div>
                    </section>

                </div>
            </div>
        </Layout>
    )
}

export default CheckoutSuccessPage
